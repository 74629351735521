.button, nav.navbar, .card-header-title {
    .icon {
        height: 100%;
    }
}

@include touch {
    .navbar-item:not(.has-dropdown), .navbar-link {
        display: flex;
    }
}

aside {
    .menu-list {
        li a.has-icon {
            display: flex;

            .icon {
                i {
                    height: auto;
                }
            }

            // .menu-item-label {
            //   line-height: 1.5rem;
            //   height: 1.5rem;
            // }
        }
    }
}

