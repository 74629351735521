
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.el-form-item__label {
    text-align: left;
}

.el-form-item.is-error {
    .el-input {
        @apply border rounded border-red-400;

            input {
                @apply border-none;
            }
    }
}

.el-input-number {
    @apply w-full;
}
