
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.status {
    height: 16px;

    padding: 2px 4px;
    margin-top: 3px;
    margin-left: 7px;
    font-size: 9px;
    font-weight: bold;
    color: white;

    border-radius: 5px;
    background: #1BD12D;
    letter-spacing: 0.05rem;
    cursor: default;
}

.managerLogo {
    cursor: pointer;
}
.el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
    box-shadow: 0 10px 20px rgba(38, 50, 72, 0.05), inset 0 1px 0 #FFFFFF, inset 0 -1px 0px #CFD6E3;
}
