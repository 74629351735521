
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

aside {
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    display: flex;
    padding: 0 1rem;
    z-index: 30;
    position: fixed;
    flex-direction: column;
    background: inherit;
    @media screen and (max-width: 768px) {
        display: none;
    }
    @media screen and (max-width: 1280px) {
        width: 4rem;
        padding: 0 7px;
        ::v-deep {
            ul {
                li {
                    .label {
                        display: none;
                    }
                }
            }
            .logo {
                span {
                    display: none;
                }
            }
            .el-card__body {
                .managerLogo {
                    display: flex;
                }
                .manager {
                    display: none;
                }
            }
            
        }
    }
}
.menuOpen {
    @media screen and (max-width: 768px) {
        display: flex;
    }
    @media screen and (max-width: 1280px) {
        width: 21rem;
        padding: 0 1rem;
        ::v-deep {
            ul {
                li {
                    .label {
                        display: flex;
                    }
                }
            }
            .logo {
                span {
                    display: flex;
                }
            }
            .el-card__body {
                .managerLogo {
                    display: none;
                }
                .manager {
                    display: flex;
                }
            }
        }
    }
}
.el-menu--collapse {
    width: 50px;
}
