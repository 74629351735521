.modal-background {
    background: rgba(0, 0, 0, 0.2);
}

.modal-card {
    $padding: 30px;
    
    max-width: 650px;
    border-radius: 10px;

    .columns {
        margin-top: 0;
        margin-bottom: 0;
    }

    &--tiny {
        max-width: 300px;
    }

    &-head {
        padding: $padding;
        border-bottom: none;
        justify-content: space-between;
    }

    &-body {
        padding: 0 $padding;
    }

    &-foot {
        padding: $padding;
        background-color: white;
    
        .column {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
