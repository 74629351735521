
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

aside {
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    display: flex !important;
    width: 20rem;
    position: fixed;
    flex-direction: column;
    background: inherit;
    z-index: 40;
    @media screen and (max-width: 768px) {
        display: none !important;
    }
    @media screen and (max-width: 1366px) {
        width: 4rem;
        padding: 0 7px;
        ::v-deep {
            ul {
                li {
                    .label {
                        display: none;
                    }
                }
            }
            .logo {
                span {
                    display: none;
                }
            }
            .el-card__body {
                .managerLogo {
                    display: flex;
                }
                .manager {
                    display: none;
                }
            }

        }
    }
}
.menuOpen {
    @media screen and (max-width: 1024px) {
        display: flex !important;
        @apply px-4;
        width: 21rem;
        ::v-deep {
            ul {
                li {
                    .label {
                        display: flex;
                    }
                }
            }
            .logo {
                span {
                    display: flex;
                }
            }
            .el-card {
                .el-card__body {
                    .managerLogo {
                        display: none;
                    }
                    .manager {
                        display: flex;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 426px) {
        @apply w-full;
    }
}
footer {
    @media screen and (min-width: 1366px) {
        display: block !important;
    }
}
.el-menu--collapse {
    width: 50px;
}

.text-xs {
    text-align: center;
}
