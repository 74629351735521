
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

input {
    &[type="checkbox"] {
        display: none;

        & + span {
            color: $success !important;
            border: 1px solid $success !important;
            background-color: transparent !important;
        }

        &:checked {
            & + span {
                color: white !important;
                background-color: $success !important;
            }
        }
    }
}
