
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

$border-color: #dbdbdb;
$border-color-invert: #b5b5b5;

// Убирает стрелки в input
::v-deep {
    .el-input {
        @apply border-none;

        input[type="number"] {
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            & {
                -moz-appearance: textfield;
            }
            &:hover, &:focus {
                -moz-appearance: number-input;
            }
        }
    }
}

.invalid {
  border-color: $danger;
}

::v-deep {
    .el-input-number {
        input {
            @apply border-none text-left;
        }
    }

    .v-select {
        @apply rounded-none laptop:border-l laptop:border-r max-h-10;

        @media screen and (max-width: 769px) {
            @apply border-t border-b;
        }

        .vs__dropdown-toggle {
            @apply max-h-10;
        }
    }

    .is-error {
        .v-select {
            @apply border-red-400 rounded-none;
        }
    }
}
