
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.splash-screen {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-direction: column;

    img {
        width: 60%;
        display: block;
        max-width: 280px;
    }

    .el-container {
        max-height: 100px;
        width: 100px;
    }
}
