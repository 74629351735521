@import "colors";

.block {
    $self: &;

    &-box {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &-card {
        border: 2px solid transparent;
        padding: 1rem;
        position: relative;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;
    }

    &-title {
        font-size: 1.125em;
        margin-bottom: 0.833em;
        margin-left: 30px;
        color: #000;
    }

    &-field {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.labelText {
    color: #000;
    font-size: 0.875em;
    margin-bottom: 0.714em;
    margin-left: 1.357em;
}

//.flex {
//    display: flex;
//
//    &-jcfe {
//        justify-content: flex-end;
//    }
//
//    &-row {
//        margin: 0 -7.5px;
//    }
//}

.row-grid {
    display: grid;

    &--checkbox-cover {
        grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
        grid-gap: 10px;
    }
}

.b-radio.radio input[type=radio] + .check.is-info {
    border-color: $info;
}

.b-radio.radio input[type=radio] + .check.is-success {
    border-color: $success;
}

.b-radio.radio input[type=radio] + .check.is-warning {
    border-color: $warning;
}

.b-radio.radio input[type=radio] + .check.is-danger {
    border-color: $danger;
}

.stat-img {
    width: 60px;
    height: 60px;
    background-color: #41A0DA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: relative;
    flex-shrink: 0;

    &::before {
        display: block;
        content: '';
        border: 8px solid rgba(65, 160, 218, 0.15);
        position: absolute;
        left: -8px;
        top: -8px;
        bottom: -8px;
        right: -8px;
        border-radius: 50%;
    }

    &--warn {
        background-color: #FFD057;

        &::before {
            border-color: rgba(255, 208, 87, 0.15);
        }
    }

    &--success {
        background-color: #73D68F;

        &::before {
            border-color: rgba(115, 214, 143, 0.15);
        }
    }

    &-cover {
        display: flex;
        align-items: center;
    }

    &-text {
        font-size: 1.25em;
        color: #192038;
        margin-left: 1.2em;

        &--total {
            font-size: 1.563em;
        }
    }
}

.stats-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mb15 {
    margin-bottom: 15px;
}

.wrapper {
    width: 100%;
    padding: 1rem;
    margin: 0 auto;

    &.centered {
        max-width: 1000px;
    }
}

.ticket-link {
    color: #6E8CF8;
    cursor: pointer;
}
