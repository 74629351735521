:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

:export{
  mainFont:"Rubik";
  bodyFonts:"Rubik",BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
  monospaced:"Fira Mono"
}

:export{
  sizeNormal:.875rem
}

:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

.f-col{
  padding:.5rem
}

::v-deep .el-input{
  border-style:none
}

::v-deep .el-input input[type=number]::-webkit-outer-spin-button,::v-deep .el-input input[type=number]::-webkit-inner-spin-button{
  -webkit-appearance:none;
  margin:0
}

::v-deep .el-input input[type=number]{
  -moz-appearance:textfield
}

::v-deep .el-input input[type=number]:hover,::v-deep .el-input input[type=number]:focus{
  -moz-appearance:number-input
}

.invalid{
  border-color:#ff4757
}

::v-deep .el-input-number input{
  border-style:none;
  text-align:left
}

::v-deep .v-select{
  max-height:2.5rem;
  border-radius:0px
}

@media (min-width: 769px){
  ::v-deep .v-select{
    border-right-width:1px;
    border-left-width:1px
  }
}

@media screen and (max-width: 769px){
  ::v-deep .v-select{
    border-top-width:1px;
    border-bottom-width:1px
  }
}

::v-deep .v-select .vs__dropdown-toggle{
  max-height:2.5rem
}

::v-deep .is-error .v-select{
  border-radius:0px;
  --tw-border-opacity:1;
  border-color:rgba(248, 113, 113, var(--tw-border-opacity))
}