
// #TODO Переосмыслить, нужно ли это глобально?
.card:not(:last-child) {
    @media screen and (min-width: 768px) {
        margin-bottom: $default-padding;
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 0 !important;
        padding-bottom: 2em;
    }
}

.card {
    border-radius: 10px;

    .card-header {
        font-size: 18px;
        line-height: 21px;
    }

    &.has-table {
        .card-content {
            padding: 0;
        }
    }

    .card-content {
        hr {
            //margin-left: $card-content-padding * -1;
            //margin-right: $card-content-padding * -1;
        }
    }

    .has-widget-icon {
        //border-right: 1px solid $white-bis;
    }

    .is-widget-icon {
        //background-color: $white-bis;
        //border-radius: $radius-rounded;

        .icon {
            width: 5rem;
            height: 5rem;
        }
    }
}

.card-content-wrapper {
    // position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
