
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.alert {
    @apply mb-3;

    ::v-deep {
        .el-alert__title {
            @apply text-sm;
        }
    }
}

.alert__title {
    @apply text-sm;
}

.form {
    .notification {
        left: 50%;
        width: 200%;
        padding: 1em 1.10295em;
        font-size: 0.85em;
        max-width: 500px;
        transform: translateX(-50%);
    }

    &-wrapper {
        z-index: 10;
    }
}

@media screen and (min-width: 1025px) {
    .form {
        top: 50%;
        right: 22vw;
        position: absolute;
        transform: translateY(-50%);
    }
}
