.form {
    $self: &;
    width: 100%;
    max-width: 240px;

    &-wrapper {
        margin: auto;
    }

    &-logo {
        margin: auto;
        padding-bottom: 2em;
        max-width: 220px;
    }

    &-errors {
        padding: 1em 0;

        #{$self}-error {
        }
    }

    &-input {
        margin-bottom: 1rem !important;
    }

    &-captcha {
        margin-bottom: 20px;
    }

    .button {
        & + a {
            margin-top: 8px;
        }
    }

    &-forgot,
    &-subbutton {
        display: block;
        color: #131313;
        cursor: pointer;
        font-size: smaller;
        text-align: center;
    }

    &-subbutton {
        color: #9C9C9C;
    }

    &-select {
        margin-bottom: 15px;
    }
}
