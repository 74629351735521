  .confirm {
    &-mail {
      position: absolute;
      z-index: 31;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $white;

      img, h1 {
        margin-bottom: 30px;
      }

      &:before, &:after {
        position: absolute;
        top: 0;
        height: 100vh;
        width: 36vw;
      }

      &:before {
        content: url("~@/assets/Auth/main-screen-left.svg");
        left: 0;
      }

      &:after {
        content: url("~@/assets/Auth/main-screen-right.svg");
        right: 0;
      }

        .container {
            max-width: 270px;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
  }
