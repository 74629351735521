.chart {
    min-height: 500px;
    //noinspection SassScssUnresolvedVariable
    border-radius: $radius-large;
}

.chart-tooltip {
    &__list {
        margin-top: 10px;
    }

    &__item {
        display: flex;
        align-items: center;
    }

    &__item-value {
        margin-left: auto;
        padding-left: 1em;
    }
}
