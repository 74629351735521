
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

$light-border: rgb(222, 227, 236);
$light-text: rgb(96, 98, 102);;

.form {
    .notification {
        left: 50%;
        width: 200%;
        padding: 1em 1.10295em;
        font-size: 0.85em;
        max-width: 500px;
        transform: translateX(-50%);
    }

    ::v-deep {
        .el-form-item__error {
            position: relative;
        }
    }

    &-wrapper {
        z-index: 10;
    }

    .is-default-style {
        ::v-deep {
            .el-input {
                border-color: $light-border;
            }
            .el-form-item__error {
                color: $light-text;
            }
        }
    }
}
@media screen and (min-width: 1025px) {
    .form {
        top: 50%;
        right: 22vw;
        position: absolute;
        transform: translateY(-50%);
    }
}

.manager-select {
    ::v-deep {
        select {
            padding-right: 0.5em !important;
        }
    }
}
