
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.el-alert {
    width: 80%;
    left: 10px;
    z-index: 40;
    bottom: 10px;
    position: fixed;
    max-width: 600px;
    align-items: start;
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
    background-color: #E2C21A;

    ::v-deep {
        .el-alert__title {
            font-size: 1.25rem;
        }
        .el-alert__description {
            text-align: right;
            font-size: 1rem;
        }
    }
}
