
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

::v-deep {
    .el-dialog__footer {
        @apply flex;
    }
}

@keyframes error {
    $error: #fc5c5c;
    0% {
        border: 2px solid white;
    }

    25% {
        border: 2px solid $error;
    }

    50% {
        border: 2px solid white;
    }

    75% {
        border: 2px solid $error;
    }

    100% {
        border: 2px solid white;
    }
}

.modal-card {
    border: 2px solid white;

    &.error {
        animation: 1500ms error;
    }

    ::v-deep {
        .common-table {
            thead {
                tr {
                    th {
                        top: 0 !important;
                    }
                }
            }
        }
    }
}
