
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.el-row::before, .el-row::after {
    content: none;
}

.el-popper .popper__arrow, .el-popper .popper__arrow::after {
    display: none;
}

.el-dropdown-menu {
    top: 64px!important;

    @media (max-width: 991px) {
        top: 40px!important;
    }
}

.header {
    display: flex!important;

    @media (min-width: 426px) and (max-width: 640px) {
        @apply mb-6;
    }
}
