$table-border-radius: 10px;

table.table {
    thead {
        th {
            border-bottom-width: 1px;
        }
    }

    td {
        font-size: 0.75em;

        &.is-progress-col {
            min-width: 5rem;
            vertical-align: middle;
        }

        figure {
            overflow: hidden;
            border-radius: 6px;
            border: 1px solid #ececec;
        }
    }
}

.b-table {

    .table {
        border: 0;
        border-radius: 0;
    }

    th {
        &.is-sortable {
          padding-right: 2em;
        }
    }
}

.table-wrapper {
    /* This stylizes buefy's pagination */
    & + .level, .b-table + .pagination {
        margin: 0;
        padding: $notification-padding;
        border-top: $base-color-light;
        //noinspection SassScssUnresolvedVariable
        //padding-left: $card-content-padding;
        //noinspection SassScssUnresolvedVariable
        //padding-right: $card-content-padding;
        // background: $white;

        .pagination-link {
            color: $button-color;
            //noinspection SassScssUnresolvedVariable
            //background: $button-background-color;
            //noinspection SassScssUnresolvedVariable
            //border-color: $button-border-color;

            &.is-current {
                //noinspection SassScssUnresolvedVariable
                //border-color: $button-active-border-color;
            }
        }

        .pagination-previous, .pagination-next, .pagination-link {
            //noinspection SassScssUnresolvedVariable
            //border-color: $button-border-color;
            // color: $base-color;

            &[disabled] {
                background-color: transparent;
            }
        }

        .pagination-list {
            justify-content: flex-end;
        }
    }

    //noinspection SassScssUnresolvedMixin
    @include tablet {
        & {
            overflow-x: visible !important;
        }
    }
}

//noinspection SassScssUnresolvedMixin
@include mobile {
    .card {
        &.has-table {
            .b-table {
                .table-wrapper + .level {
                    .level-left + .level-right {
                        margin-top: 0;
                    }
                }
            }
        }

        &.has-mobile-sort-spaced {
            .b-table {
                .field.table-mobile-sort {
                    padding-top: $default-padding * .5;
                }
            }
        }
    }

    .b-table {
        .field.table-mobile-sort {
            padding: 0 $default-padding * .5;
        }

        .table.has-mobile-cards {
            tr {
                box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
                margin-bottom: 3px !important;
            }

            td {
                &.is-progress-col {
                    span {
                        display: flex;
                        width: 45%;
                        align-items: center;
                    }
                }

                &.checkbox-cell, &.is-image-cell {
                    border-bottom: 0 !important;
                }

                &.checkbox-cell, &.is-actions-cell {
                    &:before {
                        display: none;
                    }
                }

                &.has-no-head-mobile {
                    &:before {
                        display: none;
                    }

                    span {
                        display: block;
                        width: 100%;
                    }

                    &.is-progress-col {
                        progress {
                            width: 100%;
                        }
                    }

                    &.is-image-cell {
                        .image {
                            width: $table-avatar-size-mobile;
                            height: auto;
                            margin: 0 auto $default-padding * .25;
                        }
                    }
                }
            }
        }
    }
}
