
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

::v-deep {
    .el-loading-mask {
        @apply z-20;
    }
}

.full {
    display: flex;
}

@media screen and (min-width: 1366px) {
    .main {
        padding-left: 20rem !important;
    }
}

.main {
    padding: 0 1rem 1rem 4rem;
    min-height: 100vh;
    @media screen and (max-width: 768px) {
        padding: 0;
    }

    //.section {
    //    @media screen and (max-width: 768px) {
    //        margin-top: 1rem;
    //    }
    //}
}

.el-main {
    @media screen and (max-width: 768px) {
        padding: 10px;
    }
}
