:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

:export{
  mainFont:"Rubik";
  bodyFonts:"Rubik",BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
  monospaced:"Fira Mono"
}

:export{
  sizeNormal:.875rem
}

:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

.f-col{
  padding:.5rem
}

.form.in-modal{
  margin:auto;
  transform:none;
  position:static
}

@media screen and (min-width: 1025px){
  .form{
    top:50%;
    right:22vw;
    position:absolute;
    transform:translateY(-50%)
  }
}

::v-deep .el-form-item.is-error{
  margin-bottom:2rem
}

@media (min-width: 1280px){
  ::v-deep .el-form-item.is-error{
    margin-bottom:1.5rem
  }
}