
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.partner {
    $self: &;

    display: flex;
    width: 26rem;

    &-body {
        padding: .8rem;

        #{ $self }-title {
            margin-bottom: 0.5rem;
            font-weight: 700;
            font-size: 1.03em;
            color: #212121;
        }

        #{ $self }-info {
            font-size: 0.83em;
            line-height: 1.1rem;
            margin-bottom: 0.8rem;
        }
    }
}

.dropdown-menu-item {
    @apply text-base p-3 h-auto flex items-center border-t;
}

.login {
    @media screen and (max-width: 350px) {
        display: none;
    }
}
