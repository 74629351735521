
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

//.label {
//    @media screen and (max-width: 1024px) {
//        display: none;
//    }
//}
.el-menu-item {
    padding-left: 0!important;
    padding-right: 0!important;
}

.el-menu-item.is-active {
    background-color: #F3F5F9;
    border-color: #FE2615;
    color: #162239;
}
