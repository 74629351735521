
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.control {
    &::v-deep {
        .vs {
            $self: &;
            &__selected {
                border: 1px solid rgba(140, 140, 140, .26);
            }

            &__spinner {
                font-size: 3px;
                border: .9em solid hsl(0deg 0% 100% / 10%);
                border-left-color: rgba(60,60,60,.45);
                animation: vSelectSpinner .7s linear infinite;
                margin-right: 5px;
            }

            &__search::placeholder,
            &__dropdown-toggle,
            &__dropdown-menu {
                font-family: inherit;
                font-size: 0.875rem;
                border-radius: 5px;
                padding: 6px 0;
            }

            &__search {
                padding: 0 4px;
            }

            &__clear,
            &__open-indicator {
                fill: #ffffff;
            }

            &__dropdown-menu {
                margin: 5px 0 5px;
            }
        }

        .vs__dropdown-toggle {
            @apply border-0;
            min-height: 40px;
        }

        &.not-empty {
            .vs__selected-options {
                padding: 0 4px;
                min-height: 25px; // предотвращает уменьшение высоты селекта при открытии
            }
        }

        &.vs {
            &--open {
                .vs__dropdown-toggle {
                    border:none;
                }

                .vs__search {
                    margin-top: 0 !important;
                }
            }

            &--disabled {
                .vs__dropdown-toggle {
                    cursor: not-allowed;
                }

                .vs__actions {
                    * {
                        display: none;
                    }
                    .vs__spinner {
                        display: flex;
                    }
                }
            }

            &--single {
                .vs__selected-options {
                    white-space: nowrap; /* Запрещаем перенос строк */
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top: -4px;
                }

                .vs__selected {
                    background-color: transparent;
                    border-color: transparent;
                    .vs__open {
                        margin-top: 5px;
                    }

                    + .vs__search {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        &.is-opened-top {
            top: auto;
            bottom: 100%;
        }

        &.is-fixed {
            .vs__selected-options {
                white-space: pre;
                overflow: hidden;
                flex-wrap: unset;
            }
        }

        background-color: white;
        border-radius: 4px;
    }
}

.bordered {
    border: 1px solid #dee3ec;
}

.el-form-item.is-error {
    .bordered {
        border-color: #F56C6C;
    }
}
