
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

@mixin sphere ($top, $left, $right, $width) {
    position: absolute;
    top: $top;
    left: $left;
    right: $right;
    z-index: -2;
    width: $width;


    @media screen and (max-width: 1024px) {
        width: $width * 2.2;
    }

    @media screen and (max-width: 425px) {
        width: $width * 3;
    }
}

.background {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  position: fixed;
  background: linear-gradient(180deg, #E2E6EF 0%, #F9FAFB 100%);

    .faces {
        img {
            position: absolute;
            bottom: 0;
            left: 0;

            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }

    .mouse-parallax-bg {
        transition: .5s ease;
    }

    .spheres {
        position: absolute;
        width: 100vw;
        height: 100vh;

            .sphere-1 {
                @include sphere($top: 56vh, $left: auto, $right: 16vw, $width: 5vw);
            }

            .sphere-2 {
                @include sphere($top: 17vh, $left: auto, $right: 26vw, $width: 3.9vw);
            }

            .sphere-3 {
                @include sphere($top: 10vh, $left: auto, $right: 16vw, $width: 1.8vw);
            }

            .sphere-4 {
                @include sphere($top: 2.8vh, $left: auto, $right: 21vw, $width: 1vw);
            }

            .sphere-5 {
                @include sphere($top: 83vh, $left: auto, $right: 34vw, $width: 2.8vw);
            }

            .sphere-6 {
                @include sphere($top: 91vh, $left: auto, $right: 24vw, $width: 2vw);
            }

            .sphere-7 {
                @include sphere($top: 87vh, $left: auto, $right: 17.5vw, $width: 1vw);
            }

            .sphere-8 {
                @include sphere($top: -0.5vh, $left: auto, $right: 38vw, $width: 1.1vw);
            }

            .sphere-9 {
                @include sphere($top: 29vh, $left: 38vw, $right: auto, $width: 2.5vw);
            }

            .sphere-10 {
                @include sphere($top: 37vh, $left: 34vw, $right: auto, $width: 1.4vw);
            }

            .sphere-11 {
                @include sphere($top: 56vh, $left: 43vw, $right: auto, $width: 1.4vw);
            }

            .sphere-12 {
                @include sphere($top: 72vh, $left: 26vw, $right: auto, $width: 1.8vw);
                z-index: 1;
            }

            .sphere-13 {
                @include sphere($top: 81vh, $left: 11vw, $right: auto, $width: 2.3vw);
                z-index: 1;
            }

            .sphere-14 {
                @include sphere($top: 82vh, $left: 24vw, $right: auto, $width: 1vw);
                z-index: 1;
            }

            .sphere-15 {
                @include sphere($top: 77vh, $left: 32vw, $right: auto, $width: 0.4vw);
                z-index: 1;
            }

            .sphere-16 {
                @include sphere($top: 59vh, $left: 7vw, $right: auto, $width: 1vw);
                z-index: 1;
            }

            .sphere-17 {
                @include sphere($top: 20vh, $left: 1.6vw, $right: auto, $width: 1.5vw);
                z-index: 1;
            }

            .sphere-18 {
                @include sphere($top: 21vh, $left: 27vw, $right: auto, $width: 1vw);
                z-index: 1;
            }

            .sphere-19 {
                @include sphere($top: 24vh, $left: 30vw, $right: auto, $width: 0.5vw);
                z-index: 1;
            }
    }
}
