
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

::v-deep {
    .el-progress-bar {
        &__inner {
            display: flex;
            justify-content: flex-end;
        }

        &__innerText {
            font-size: calc(0.875rem / 1.5);
            line-height: 0.875rem;
            font-weight: 700;
            white-space: nowrap;
        }
    }
}
