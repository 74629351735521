
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.status {
    height: 16px;

    padding: 2px 4px;
    font-size: 9px;
    font-weight: bold;
    color: white;

    border-radius: 5px;
    background: #1BD12D;
    letter-spacing: 0.05rem;
    cursor: default;
}

::v-deep {
    .el-dialog {
        @media screen and (max-width: 991px) {
            width: 90%;
        }
        
        .el-dialog__body {
            padding: 20px 32px 20px;
            
            @media screen and (max-width: 991px) {
                padding: 20px 15px 20px;
            }
        }
        
        .el-dialog__footer {
            padding: 10px 32px 32px;
        }
        
        .el-card__body {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0.75rem;
        }
        
        //.el-checkbox-group {
        //    &:before {
        //        position: relative;
        //        content: url("../../assets/Table/line.svg");
        //        margin-right: 20px;
        //        bottom: 4px;
        //    }
        //}
        
        .el-switch__label * {
            color: #162239;
            font-weight: 400;
            
            @media screen and (max-width: 991px) {
                //display: none;
            }
        }
    }
    
}
