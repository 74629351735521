
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.multilined-tooltip {
    white-space: pre-line !important
}

.block-box {
    min-height: 83px;
    position: relative;
}

::v-deep {
    .b-tooltip {
        &.is-multiline:after {
            text-align: left;
            white-space: pre-wrap;
        }

        .icon {
            margin-left: 0.25em;
        }
    }
}
